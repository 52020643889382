import dynamic from 'next/dynamic';
import React from 'react';

import { PromoBannerType } from '../../pages/api/promoBanner';
import { BrandLogo } from '../_core/BrandLogo';
import MaxWidth from '../shared/MaxWidth';
import { DownloadMacAppBanner } from './components/DownloadMacAppBanner';
import { PartnerBanner, PartnerType } from './components/PartnerBanner';
import { PromoBanner } from './components/PromoBanner';
import * as S from './Header.styles';
import { HeaderActionButton } from './HeaderActionButton';

type Props = {
  headerActions: JSX.Element;
  partner?: PartnerType;
  promoBanner: PromoBannerType | null;
  isDownloadPage: boolean;
  isScrolled: boolean;
  downloadMacAppBannerVisible: boolean;
  onDownloadMacAppClose: () => void;
  onDownloadMacAppNavigate: () => void;
};

const Navigation = dynamic(() => import('./Navigation'), {
  ssr: true,
});

export function HeaderDisplay(props: Props): JSX.Element {
  return (
    <S.Container>
      {props.partner ? <PartnerBanner partner={props.partner} /> : null}
      {!props.partner && props.promoBanner ? <PromoBanner data={props.promoBanner} /> : null}

      {!props.partner && !props.promoBanner && props.downloadMacAppBannerVisible ? (
        <DownloadMacAppBanner
          onClose={props.onDownloadMacAppClose}
          onNavigate={props.onDownloadMacAppNavigate}
        />
      ) : null}

      <S.HeaderDiv isScrolled={props.isScrolled}>
        <MaxWidth style={{ maxWidth: props.isDownloadPage ? '1220px' : '1328px' }}>
          <S.HeaderWrapper>
            <S.LeftContainer>
              <BrandLogo analytics={{ category: 'Header', action: 'Brand Header Click' }} />
              <Navigation />
            </S.LeftContainer>
            <S.RightContainer>{props.headerActions}</S.RightContainer>
          </S.HeaderWrapper>
        </MaxWidth>
      </S.HeaderDiv>
    </S.Container>
  );
}

export function HeaderSignInSignUpActionsDisplay(props: {
  onClickSignIn: () => void;
  onClickSignUp: () => void;
}): JSX.Element {
  return (
    <>
      <HeaderActionButton
        analyticsEvent={{ category: 'Header', action: 'Sign In Button Click' }}
        hasBorder={false}
        testId="sign-in"
        onClick={props.onClickSignIn}
      >
        SIGN IN
      </HeaderActionButton>
      <HeaderActionButton
        analyticsEvent={{ category: 'Header', action: 'Signup Button Click' }}
        hasBorder
        testId="sign-up"
        onClick={props.onClickSignUp}
      >
        TRY BRAIN.FM FOR FREE
      </HeaderActionButton>
    </>
  );
}

export function HeaderSignInDownloadsDisplay(props: { onClickDownload: () => void }): JSX.Element {
  return (
    <>
      <HeaderActionButton
        analyticsEvent={{ category: 'Header', action: 'Download Button Click' }}
        onClick={props.onClickDownload}
      >
        DOWNLOAD
      </HeaderActionButton>
    </>
  );
}
