import { CDN_URL } from '../globals';

export const Assets = {
  images: {
    logoFull: {
      url: `${CDN_URL}/images/logo-full.svg`,
      alt: 'BrainFM logo',
    },
    logoPurple: {
      url: `${CDN_URL}/images/logo-purple.png`,
      alt: 'BrainFM logo',
    },
    betterThanBinuaral: {
      url: `${CDN_URL}/images/better-than-binuaral.png`,
      alt: '',
    },
    featuredLogos: {
      url: `${CDN_URL}/images/featured-logos.png`,
      alt: 'featured in',
    },
    focusAnimation: {
      url: `${CDN_URL}/images/focus-animation.gif`,
      alt: 'A young man in a light blue shirt, seated at a desk working while listening to Brain FM.',
    },
    neuralPhaseLockingScience: {
      url: `${CDN_URL}/images/neural-phase-locking-science.png`,
      alt: '',
    },
    neuralPhaseLocking: {
      url: `${CDN_URL}/images/neural-phase-locking.gif`,
      alt: 'A young man in a light blue shirt, seated at a desk working while listening to Brain FM.',
    },
    ourProcess: {
      url: `${CDN_URL}/images/our-process.gif`,
      alt: '',
    },
    placeboEffect: {
      url: `${CDN_URL}/images/placebo-effect.gif`,
      alt: '',
    },
    socialLogoNew: {
      url: `${CDN_URL}/images/social.jpg`,
      alt: '',
    },
    listenMyBrainFM: {
      url: `${CDN_URL}/images/listen-mybrainfm.svg`,
      alt: '',
    },
    listenGoogle: {
      url: `${CDN_URL}/images/listen-google.svg`,
      alt: '',
    },
    listenApple: {
      url: `${CDN_URL}/images/listen-apple.svg`,
      alt: '',
    },
    focusDesk: {
      url: `${CDN_URL}/images/hero-focus_desk.png`,
      alt: 'focus desk',
    },
    heroBg: {
      url: `${CDN_URL}/images/hero-bg.png`,
      alt: 'hero-bg',
    },
    heroAura: {
      url: `${CDN_URL}/images/hero-aura.svg`,
      alt: 'hero-aura',
    },
    featuredIn: {
      url: `${CDN_URL}/images/featured_in.png`,
      alt: 'featuredIn',
    },
    scienceFunctional: {
      url: `${CDN_URL}/images/science-functional.png`,
      alt: 'functional music',
    },
    scienceBrains: {
      url: `${CDN_URL}/images/science-brains.png`,
      alt: 'functional brains',
    },
    scienceAwards: {
      url: `${CDN_URL}/images/science-awards.png`,
      alt: 'functional awards',
    },
    iconPlay: {
      url: `${CDN_URL}/images/icon-play.png`,
      alt: 'icon play',
    },
    quoteBg: {
      url: `${CDN_URL}/images/quote-bg.svg`,
      alt: 'quote-bg',
    },
    ctaLandingPreview: {
      url: `${CDN_URL}/images/marketing/app_flat_full.webp`,
      alt: 'preview of the brain.fm app',
    },
    ctaLandingMobilePreview: {
      url: `${CDN_URL}/images/marketing/mobile-app-flat.webp`,
      alt: 'preview of the brain.fm mobile app',
    },
    ctaLandingGradientRight: {
      url: `${CDN_URL}/images/marketing/gradient_right_full.webp`,
      alt: 'gradient',
    },
    ctaLandingGradientLeft: {
      url: `${CDN_URL}/images/marketing/gradient_left.webp`,
      alt: 'gradient',
    },
    aboutHero: {
      url: `${CDN_URL}/images/about-hero.svg`,
      alt: 'About Brain.FM',
    },
    missionIcon: {
      url: `${CDN_URL}/images/mission-icon.svg`,
      alt: 'Brain.FM Mission',
    },
    visionIcon: {
      url: `${CDN_URL}/images/vision-icon.svg`,
      alt: 'Brain.FM Vision',
    },
    ceo: {
      url: `${CDN_URL}/images/ceo.png`,
      alt: 'Brain.FM CEO',
    },
    teamPhoto: {
      url: `${CDN_URL}/images/team-1.png`,
      alt: 'Brain.FM Team',
    },
    teamPhotoAlt: {
      url: `${CDN_URL}/images/team-2.png`,
      alt: 'Brain.FM Team',
    },
    scienceTeam: {
      kevin: {
        url: `${CDN_URL}/images/science-team/kevin-2.png`,
        alt: 'Kevin JP Woods, PhD',
      },
      psyche: {
        url: `${CDN_URL}/images/science-team/psyche-2.png`,
        alt: 'Psyche Loui, PhD',
      },
      benjamin: {
        url: `${CDN_URL}/images/science-team/benjamin-2.png`,
        alt: 'Benjamin Morillon, PhD',
      },
    },
    scienceBg: {
      url: `${CDN_URL}/images/science-bg-wave.svg`,
      alt: 'Science',
    },
    scienceBgMobile: {
      url: `${CDN_URL}/images/science-bg-wave-mobile-2.svg`,
      alt: 'Science',
    },
    scienceFoundation: {
      url: `${CDN_URL}/images/science-foundation-2.png`,
      alt: 'Science Foundation',
    },
    sciencePerformance: {
      url: `${CDN_URL}/images/science-performance-2.png`,
      alt: 'Performance testing with placebo controls',
    },
    sciencePerformanceMobile: {
      url: `${CDN_URL}/images/science-performance-mobile.png`,
      alt: 'Performance testing with placebo controls mobile',
    },
    scienceEeg: {
      url: `${CDN_URL}/images/science-eeg-2.png`,
      alt: 'Electrical activity in the brain: EEG studies',
    },
    scienceFmri: {
      url: `${CDN_URL}/images/science-fmri-2.png`,
      alt: 'Blood flow in the brain: FMRI studies',
    },
    sciencePatented: {
      url: `${CDN_URL}/images/science-patented-2.png`,
      alt: 'Patented neural entrainment',
    },
    scienceIconNoDistraction: {
      url: `${CDN_URL}/images/science-icon-no-distraction-2.png`,
      alt: 'No Distraction',
    },
    scienceIconBeats: {
      url: `${CDN_URL}/images/science-icon-beats-2.png`,
      alt: 'Binaural Beats',
    },
    scienceTested: {
      url: `${CDN_URL}/images/science-tested-2.png`,
      alt: 'Tested with science',
    },
    scienceIconPrecision: {
      url: `${CDN_URL}/images/science-icon-precision-2.png`,
      alt: 'Scientific precision',
    },
    scienceIconBrain: {
      url: `${CDN_URL}/images/science-icon-brain-2.png`,
      alt: 'Affects differently',
    },
    scienceBlueBgAura: {
      url: `${CDN_URL}/images/science-card-blue-bg-aura.png`,
      alt: 'Science card blue aura',
    },
    scienceRedBgAura: {
      url: `${CDN_URL}/images/science-card-red-bg-aura.png`,
      alt: 'Science card red aura',
    },
    scienceLiteratureWave: {
      url: `${CDN_URL}/images/science-literature-wave.png`,
      alt: 'Science literature wave',
    },
    scienceResearchWave: {
      url: `${CDN_URL}/images/science-research-wave.png`,
      alt: 'Science research wave',
    },
    cardBg: {
      url: `${CDN_URL}/images/card-bg.png`,
      alt: 'Card',
    },
    mac: {
      url: `${CDN_URL}/images/mac.png`,
      alt: 'Mac',
    },
    heroMarch23: {
      url: `${CDN_URL}/images/images_hero_march_2023_opt_full.png`,
      alt: 'Brain.FM',
    },
    heroMobileMarch23: {
      url: `${CDN_URL}/images/hero_mobile_march_23.png`,
      alt: 'Brain.FM',
    },
    iphones: {
      url: `${CDN_URL}/images/iphones.png`,
      alt: 'iphones',
    },
    appleSet: {
      url: `${CDN_URL}/images/apple-set.png`,
      alt: 'appleSet',
    },
    gradientTrack1: {
      url: `${CDN_URL}/images/gradient-track-1.png`,
      alt: 'Gradient',
    },
    gradientTrackMarch23: {
      url: `${CDN_URL}/images/images_gradient-track-march-23.png`,
      alt: 'Gradient',
    },
    gradientTrackOct3: {
      url: `${CDN_URL}/images/gradient-track-oct-3.png`,
      alt: 'Gradient',
    },
    productDescription1: {
      url: `${CDN_URL}/images/product_description_1.png`,
      alt: 'Brain.FM',
    },
    productDescription2: {
      url: `${CDN_URL}/images/science_description.png`,
      alt: 'Brain.FM',
    },
    nsf: {
      url: `${CDN_URL}/images/nsf.png`,
      alt: 'NSF',
    },
    focusAura: {
      url: `${CDN_URL}/images/hero-focus-aura.png`,
      alt: 'Aura',
    },
    closeIcon: {
      url: `${CDN_URL}/images/close_icon.svg`,
      alt: 'Close',
    },
    signUpFormHeader: {
      url: `${CDN_URL}/images/signup-form-header.svg`,
      alt: 'Brain.FM',
    },
    signUpModalOverlay: {
      url: `${CDN_URL}/images/signup_overlay.webp`,
      alt: 'Sign Up Overlay',
    },
    circleOfBlurs: {
      url: `${CDN_URL}/images/circle-of-blurs.png`,
      alt: 'Circle Of Blurs',
    },
    downloadAccessToAll: {
      url: `${CDN_URL}/images/download-access-to-all.png`,
      alt: 'AccessToAll',
    },
    downloadEasyAccess: {
      url: `${CDN_URL}/images/download-easy-access.png`,
      alt: 'Easy Access',
    },
    downloadNoMoreJuggling: {
      url: `${CDN_URL}/images/download-no-more-juggling.png`,
      alt: 'No More Juggling',
    },
    downloadExploreNew: {
      url: `${CDN_URL}/images/download-explore-new.png`,
      alt: 'Explore New',
    },
    downloadSeamlessControl: {
      url: `${CDN_URL}/images/download-seamless-control-3.png`,
      alt: 'Seamless control',
    },
    downloadBlur1: {
      url: `${CDN_URL}/images/download-blur-1.png`,
      alt: 'Blur 1',
    },
    downloadBlur2: {
      url: `${CDN_URL}/images/download-blur-2.png`,
      alt: 'Blur 2',
    },
    downloadBlur3: {
      url: `${CDN_URL}/images/download-blur-3.png`,
      alt: 'Blur 3',
    },
    downloadBlur4: {
      url: `${CDN_URL}/images/download-blur-4.png`,
      alt: 'Blur 4',
    },
    downloadBlurBg: {
      url: `${CDN_URL}/images/download-blur-bg.png`,
      alt: 'Blur 4',
    },
    downloadBlurMobileBg: {
      url: `${CDN_URL}/images/download-blur-mobile-bg.png`,
      alt: 'Blur 4',
    },
    downloadMacbook: {
      url: `${CDN_URL}/images/download-macbook.png`,
      alt: 'Blur 4',
    },
  },
  icons: {
    logo: {
      url: `${CDN_URL}/icons/bfm-logo.svg`,
      alt: 'BrainFM logo',
    },
    arrowRight: {
      url: `${CDN_URL}/icons/arrow-right.svg`,
      alt: '',
    },
    android: {
      url: `${CDN_URL}/icons/android_icon.svg`,
      alt: '',
    },
    apple: {
      url: `${CDN_URL}/icons/apple_icon.svg`,
      alt: '',
    },
    appleWhite: {
      url: `${CDN_URL}/icons/apple-white.svg`,
      alt: '',
    },
    google: {
      url: `${CDN_URL}/icons/google_icon.svg`,
      alt: '',
    },
    angelList: {
      url: `${CDN_URL}/icons/angel_list_icon.svg`,
      alt: 'AngelList',
    },
    categories: {
      url: `${CDN_URL}/icons/planBenefits/layers.svg`,
      alt: 'Access All Categories',
    },
    cancel: {
      url: `${CDN_URL}/icons/planBenefits/x-circle.svg`,
      alt: 'Cancel Anytime',
    },
    close: {
      url: `${CDN_URL}/icons/close_icon.svg`,
      alt: '',
    },
    download: {
      url: `${CDN_URL}/icons/planBenefits/download.svg`,
      alt: 'Offline Access',
    },
    facebook: {
      url: `${CDN_URL}/icons/facebook_gray.svg`,
      alt: 'Facebook',
    },
    fire: {
      url: `${CDN_URL}/icons/fire_icon.svg`,
      alt: 'Fire icon',
    },
    infinity: {
      url: `${CDN_URL}/icons/planBenefits/infinity.svg`,
      alt: 'Unlimited Sessions',
    },
    instagram: {
      url: `${CDN_URL}/icons/instagram_gray.svg`,
      alt: 'Instagram',
    },
    link: {
      url: `${CDN_URL}/icons/link.svg`,
      alt: 'Link',
    },
    twitter: {
      url: `${CDN_URL}/icons/x_gray.svg`,
      alt: 'X - Former Twitter',
    },
    warning: {
      url: `${CDN_URL}/icons/warning_icon.svg`,
      alt: 'warning icon',
    },
  },
  videos: {
    howItWorks: {
      url: `${CDN_URL}/videos/bfm-more.mp4`,
      alt: 'How it works',
    },
  },
  emojis: {
    eWave: {
      url: `${CDN_URL}/emojis/e_wave.png`,
      alt: '',
    },
    monkeyNoPeek: {
      url: `${CDN_URL}/emojis/monkey_no_peek.png`,
      alt: '',
    },
  },
  fonts: {
    TTNormsProBold: `${CDN_URL}/fonts/TTNormsPro-Bold.ttf`,
    TTNormsProMedium: `${CDN_URL}/fonts/TTNormsPro-Medium.ttf`,
    TTNormsProRegular: `${CDN_URL}/fonts/TTNormsPro-Regular.ttf`,
  },
  locales: {},
  pdfs: {
    performancePilot: `${CDN_URL}/pdfs/performance-pilot.pdf`,
    sleepStudy: `${CDN_URL}/pdfs/sleep-study.pdf`,
    whitePaper: `${CDN_URL}/pdfs/white-paper.pdf`,
  },
};
